@tailwind base;
@tailwind components;
@tailwind utilities;

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
  display: none;
  -webkit-appearance: none;
}

.fr-emoticon {
  background-color: green;
  padding: 10px;
}

.range-input {
  width: 100%;
  height: 4px;
  margin: auto;
  cursor: pointer;
}
.range-input::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  background-color: #ffffff;
}
.range-input::-webkit-slider-thumb {
  position: relative;
  appearance: none;
  top: 50%;
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background-color: #ffffff;
  border: 1px solid lightgray;
  transform: translateY(-50%);
  transition: 150ms;
}
.range-input::-moz-range-track {
  width: 100%;
  height: 24px;
  background-color: #ffffff;
}
.range-input::-moz-range-progress {
  width: 100%;
  height: 24px;
  background-color: #ffffff;
}
.range-input::-moz-range-thumb {
  appearance: none;
  margin: 0;
  border: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: #4b6efa;
  transition: background-color 150ms;
}
.range-input::-ms-track {
  width: 100%;
  height: 24px;
  background: transparent;
  color: transparent;
  border: 0;
}
.range-input::-ms-fill-lower {
  background-color: #ffffff;
}
.range-input::-ms-fill-upper {
  background-color: #ffffff;
}
.range-input::-ms-thumb {
  appearance: none;
  height: 24px;
  width: 24px;
  background: #ffffff;
  border-radius: 100%;
  border: 0;
  transition: background-color 150ms;
  top: 0;
  margin: 0;
  box-shadow: none;
}
.range-input:hover::-webkit-slider-thumb {
  background-color: #fafafa;
}
.range-input:hover::-webkit-range-thumb {
  background-color: #fafafa;
}
.range-input:hover::-ms-thumb {
  background-color: #fafafa;
}
.range-input:focus::-webkit-slider-thumb {
  background-color: #fafafa;
}
.range-input:focus::-webkit-range-thumb {
  background-color: #fafafa;
}
.range-input:focus::-ms-thumb {
  background-color: #fafafa;
}
.range-input:focus {
  outline: none;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast-container {
    width: 100%;
    padding: 0;
    left: 0;
    margin: 0;
  }
}

.grecaptcha-badge {
  visibility: hidden;
}
